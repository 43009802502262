import React from "react"
import Layout from '../../components/layout'; 
import DropdownMenu from '../../components/dropdownMenu'
import productStyles from '../../styles/product.module.scss'
import image from '../../images/vector-image-3.jpg';
import dropdownStyles from '../../styles/dropdown.module.scss'

const PropiedadesPage = () => {
    return(
        <Layout>
            <DropdownMenu/>
            <div className={productStyles.descripcion}>
                <h3>RPP Propiedades</h3>
                <p className={productStyles.texto}>Verificación del folio real con el cual se encuentran inscritas las propiedades en el Registro público de cada demarcación registral, para el conocimiento de la existencia o no existencia de gravámenes y estatus registral (propiedad) de las propiedades.</p>
                <img className={dropdownStyles.image} src={image} alt="imagen productos" /> 
            </div>
            
        </Layout>
    ) 
}
export default PropiedadesPage;